import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { updateDocument } from "../../../common/state/CommonActions";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { DateField } from "@mui/x-date-pickers";
import { subYears } from "date-fns";
import { debounce } from "lodash-es";
import { TEXTFIELD_DEBOUNCE_WAIT } from "../../../common/constants/HelperConstants";
import { updateFullNameOnAppointment } from "../../state/OlbActionsForNewExisting";

export function DateOfBirth(): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const patientDateOfBirth = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.dateofbirth);
  const [dateOfBirth, setDateOfBirth] = useState(patientDateOfBirth || null);
  const debouncedOnChanged = useMemo(() => debounce(onChanged, TEXTFIELD_DEBOUNCE_WAIT), []);

  let format = t("booking.patientInformation.dateOfBirth.format");
  if ("en" === (window as any).ThreePointData?.localeLanguage && "US" !== (window as any).ThreePointData?.countryCode) {
    format = "dd/MM/yyyy";
  }

  function onChangedInternal(value) {
    setDateOfBirth(value);
    debouncedOnChanged(value);
  }
  function onChanged(value) {
    let dateOfBirth = value;
    if (checkDateIsValid(dateOfBirth)) {
      dateOfBirth = new Date(Date.UTC(dateOfBirth.getFullYear(), dateOfBirth.getMonth(), dateOfBirth.getDate()));
    }
    dispatch(updateDocument(DocumentConstants.PATIENT, { dateofbirth: dateOfBirth }));
  }

  return (
    <DateField
      className={"t-patient-dateofbirth"}
      label={t("booking.patientInformation.dateOfBirth.label") + " *"}
      value={dateOfBirth}
      minDate={subYears(new Date().setHours(0, 0, 0, 0), 120)}
      onChange={onChangedInternal}
      format={format}
      disableFuture
      timezone={"UTC"}
      inputProps={{inputmode:"numeric"}}
    />
  );
}

function checkDateIsValid(date) {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    return !isNaN(date.getTime());
  } else {
    return false;
  }
}
